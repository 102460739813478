<template>
  <main class="page">
    <article class="columns">
      <div class="container-1440">
        <div class="columns__content">
          <div class="columns__column-1 profile">
            <ProfileHeader />
            <SectionsSelector section="personal_info" />
          </div>

          <div class="personal-info columns__column-2">
            <div class="settings__prompt">
              Заполните личную информацию профиля, для автоматического
              заполнения форм в разделе Формы
            </div>
            <div class="personal-info__content">
              <swiper
                :navigation="{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }"
                :pagination="{
                  el: '.swiper-pagination',
                  clickable: true,
                }"
                :slides-per-view="1"
                effect="fade"
                :fadeEffect="{
                  crossFade: true,
                }"
                class="swiper"
              >
                <swiper-slide class="swiper-slide">
                  <form class="personal-info__form form">
                    <article class="entity__flex">
                      <Input
                        @value-input="saveValue"
                        title="Фамилия"
                        field="last_name"
                        type="text"
                        placeholder="Иванов (обязательно)"
                        custom_class="form__last-name"
                        :error="errors.last_name"
                      />
                      <Input
                        @value-input="saveValue"
                        title="Имя"
                        field="first_name"
                        type="text"
                        placeholder="Иван (обязательно)"
                        custom_class="form__name"
                        :error="errors.first_name"
                      />
                    </article>
                    <article class="entity__flex">
                      <div>
                        <p class="text form__text">Пол</p>
                        <select
                          class="form__select input"
                          placeholder="Не выбрано (обязательно)"
                        >
                          <option value="Мужской">Мужской</option>
                          <option value="Женский">Женский</option>
                        </select>
                      </div>
                      <div>
                        <p class="text form__text">Дата рождения</p>
                        <select class="form__select input">
                          <option value="Дата не выбрана">
                            Выбрать дату (обязательно)
                          </option>
                        </select>
                      </div>
                    </article>
                  </form>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                  <form class="personal-info__form form">
                    <article class="entity__flex">
                      <div>
                        <p class="text form__text">Семейное положение</p>
                        <select class="form__select input">
                          <option value="Не выбрано">Не выбрано</option>
                          <option value="В браке">В браке</option>
                          <option value="Холост">Холост</option>
                        </select>
                      </div>
                      <div>
                        <p class="text form__text">Место рождения</p>
                        <select class="form__select input">
                          <option value="USA">Выбрать страну/город</option>
                          <option value="Russia">Россия</option>
                        </select>
                      </div>
                    </article>
                    <article class="entity__flex">
                      <div>
                        <p class="text form__text">Профессия</p>
                        <input
                          type="text"
                          placeholder="Инженер"
                          class="form__last-name input"
                        />
                      </div>
                      <div>
                        <p class="text form__text">Город проживания</p>
                        <select class="form__select input">
                          <option value="Выбрать город">Выбрать город</option>
                          <option value="Москва">Москва</option>
                        </select>
                      </div>
                    </article>
                  </form>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                  <form class="personal-info__form form">
                    <article class="entity__flex">
                      <div>
                        <p class="text form__text">Страна/регион проживания</p>
                        <select class="form__select input">
                          <option value="Выбрать страну/регион">
                            Выбрать страну/регион
                          </option>
                          <option value="Москва">Москва</option>
                        </select>
                      </div>
                      <div>
                        <p class="text form__text">
                          Какую бы страну вы выбрали в первую очередь?
                        </p>
                        <input
                          type="text"
                          placeholder="Выбрать страну/регион"
                          class="form__last-name input"
                        />
                      </div>
                    </article>
                    <article class="entity__flex">
                      <div>
                        <p class="text form__text">
                          Вы когда-нибудь бывали там раньше?
                        </p>
                        <input
                          type="Тext"
                          placeholder="Инженер"
                          class="form__last-name input"
                        />
                      </div>
                      <div>
                        <p class="text form__text">
                          Где вы планируете остановиться? (укажите адрес)
                        </p>
                        <input
                          type="text"
                          placeholder="Адрес"
                          class="form__last-name input"
                        />
                      </div>
                    </article>
                  </form>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                  <form class="personal-info__form form">
                    <article class="entity__flex">
                      <div>
                        <div>
                          <p class="text form__text">
                            Кто будет оплачивать ваши расходы?
                          </p>
                          <input
                            type="text"
                            placeholder="Text"
                            class="form__last-name input"
                          />
                        </div>
                      </div>
                      <div>
                        <p class="text form__text">
                          Как долго вы планируете оставаться?
                        </p>
                        <input
                          type="text"
                          placeholder="Text"
                          class="form__last-name input"
                        />
                      </div>
                    </article>
                    <article class="entity__flex">
                      <div>
                        <p class="text form__text">
                          Планируете ли вы отправиться с членами семьи?
                        </p>
                        <input
                          type="Тext"
                          placeholder="Text"
                          class="form__last-name input"
                        />
                      </div>
                      <div>
                        <p class="text form__text">
                          Пожалуйста, обеспечьте членов семьи, путешествующих с
                          вами.
                        </p>
                        <input
                          type="text"
                          placeholder="Text"
                          class="form__last-name input"
                        />
                      </div>
                    </article>
                  </form>
                </swiper-slide>
                <swiper-slide class="swiper-slide">
                  <form class="personal-info__form form">
                    <article class="entity__flex">
                      <div>
                        <p class="text form__text">
                          Ваша главная цель переезда
                        </p>
                        <select class="form__select input">
                          <option value="Выбрать цель">Выбрать цель</option>
                          <option value="Москва">Отдых</option>
                        </select>
                      </div>
                      <div>
                        <p class="text form__text">
                          Вас когда-либо депортировали из указанной страны?
                        </p>
                        <input
                          type="text"
                          placeholder="Выбрать страну/регион"
                          class="form__last-name input"
                        />
                      </div>
                    </article>
                    <article class="entity__flex">
                      <div>
                        <p class="text form__text">
                          Имеются ли у вас инфекционные заболевания, которые
                          имеют значение для общественного здравоохранения?
                        </p>
                        <input
                          type="text"
                          placeholder="Text"
                          class="form__last-name input"
                        />
                      </div>
                    </article>
                  </form>
                </swiper-slide>
              </swiper>
              <div class="swiper-pagination"></div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
            <p class="personal-info__fill">Заполнено на 65%</p>
          </div>
        </div>
      </div>
    </article>
  </main>
</template>

<script>
//import { API } from "../../helpers/api";

import SwiperCore, { Navigation, Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";

import ProfileHeader from "../../components/profile/Header.vue";
import SectionsSelector from "../../components/profile/SectionsSelector.vue";

import Input from "../../components/utils/Input.vue";

SwiperCore.use([Navigation, Pagination, EffectFade]);

export default {
  name: "MigrantPersonal",
  components: {
    ProfileHeader,
    SectionsSelector,
    Input,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        //точки
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        //стрелки
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        /* autoHeight: true, */
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
      },
      values: {
        first_name: "",
        last_name: "",
      },
      errors: {
        first_name: "",
        last_name: "",
      },
    };
  },
  methods: {
    saveValue(data) {
      this.values[data.field] = data.value;
      for (const key in this.errors) {
        this.errors[key] = "";
      }
    },
  },
};
</script>

<style>
.swiper-pagination {
  bottom: -36px;
  left: 0;
  right: 0;
}
</style>