<template>
  <div class="profile__header">
    <div class="profile__avatar">
      <picture>
        <source :srcset="avatar" type="image/webp" />
        <img :src="avatar" alt="avatar" />
      </picture>
      <picture>
        <source srcset="/img/Settings/camera.svg" type="image/webp" />
        <img
          class="profile__avatar-camera"
          src="/img/Settings/camera.svg"
          alt="camera"
        />
      </picture>
    </div>
    <div class="profile__info">
      <h2 class="profile__name">{{user.first_name}} {{user.last_name}}</h2>
      <p class="profile__fill">Профиль заполнен на 20%</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ProfileHeader',
    computed: {
    ...mapGetters(['user']),
    avatar() {
      return this.user.avatar_url ? `${location.origin}${this.user.avatar_url}` : '/img/Settings/avatar.png';
    }
  },
};
</script>